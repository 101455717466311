import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles'
import { Box, Button } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { lighten } from '@mui/system';
import Popover from '@mui/material/Popover';
const config = require("../../framework/src/config");
import CustomModal from "./CustomModal"

const caregiverView = require('./caregiver_view.svg');
const logoutIcon = require('./logout.svg');
const questionFeedback = require('./question_feedback.svg');
const settingIcon = require('./setting.svg');
const honeIcon = require('./hone_icon.svg');

interface HospiceHeaderProps {
  navigation?: any
  hideMenu?: boolean
  hospiceView?: boolean
  currentLocation?: string;
  openDashboardPage?: () => void;
}

export default function HospiceHeader(props: HospiceHeaderProps) {
  const isShowMenu = !props.hideMenu
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [isShowModalConfirm, setIsShowModalConfirm] = React.useState(false)

  useEffect(() => {
    fetchHospiceBypassedStatus()
  }, [])

  const currentUserData = localStorage.getItem("user_data") ?? ""
  const currentFlow = localStorage.getItem("main_flow") ?? ""
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const {
    navigation,
    hospiceView,
    currentLocation
  } = props;

  const fetchHospiceBypassedStatus = async () => {
    if (navigation && navigation?.getLocation('') === '/Contactus') {
      return
    }
    const token = localStorage.getItem('token');
    const headers = { 'Content-Type': 'application/json', token: token || '' }
    const response = await fetch(`${config.baseURL}/account_block/accounts/fetch_payment_bypass_status`, { headers });
    const data = await response.json();
    if (data) {
      if (data?.payment_bypassed === false) {
        if (navigation && navigation?.getLocation() === '/dashboard' && new URLSearchParams(window.location.search).get("payment_intent_client_secret")) {
          return;
        }
        const response = await fetch(`${config.baseURL}/bx_block_stripe_integration/subscriptions/has_active_subscription`, { headers });
        const data = await response.json();
        if (!data || !data?.status || (data?.status !== 'trial' && data?.status !== 'paid')) {
          if (currentLocation && currentLocation === '/Subscriptionbilling2') return
          handleLogout()
        }
      } else {
        localStorage.setItem("user_status", 'paid')
        if (currentLocation && currentLocation === '/Subscriptionbilling2') {
          localStorage.setItem("main_flow", 'hospice')
          if (navigation) {
            navigation?.navigate("Dashboard")
          }
        }
      }
    }
  }

  const getHospiceName = () => {
    if(currentUserData && currentUserData !== ""){
      const currentData = JSON.parse(currentUserData)
      return currentData?.data?.attributes.hospice_name ?? "name"
    }
    return "Not set name"
  }
  const getHospiceLogo = () => {
    if(currentUserData && currentUserData !== ""){
      const currentData = JSON.parse(currentUserData)
      return currentData?.data?.attributes.hospice_logo ? `${config.baseURL}/${currentData?.data?.attributes.hospice_logo}` : require('./hospice_logo.png')
    }
    return require('./hospice_logo.png')
  }
  const handleLogout = () => {
    localStorage.removeItem("user_data")
    localStorage.removeItem("token")
    localStorage.removeItem("current_role")
    localStorage.removeItem("user_status")
    navigation?.navigate("EmailAccountLoginBlock")
  }
  return (
    <>
      <CustomModal
        open={isShowModalConfirm}
        handleCloseModal={() => setIsShowModalConfirm(false)}
        headerTitle="Logout"
        renderContent={() => {
          return <>
            <LatoText >Are you sure you want to logout?</LatoText>
          </>
        }}
        textButtonLeft="No"
        textButtonRight="Yes"
        handleClickButtonRight={handleLogout}
        height={600}
        loading={false}
      />
      <Container>
        <HeaderContainer>
          <PartnerForCareLogoWrapper>
          <ImageContainer>
            <img style={webStyles.image} src={require('./group_logo.png')} />
          </ImageContainer>
          <ImageMobile>
            <img style={webStyles.imageMobile} src={require('./logo_mobile.png')} />
          </ImageMobile>
          </PartnerForCareLogoWrapper>
          <HospiceInfoContainer>
            <HospiceLogo src={getHospiceLogo()} />
            <HeaderText fontSize={28}>{getHospiceName()}</HeaderText>
          </HospiceInfoContainer>
          <UserPanel>
          {isShowMenu && <Button style={{background: 'transparent', boxShadow: 'none'}} aria-describedby={id} variant="contained" onClick={handleClick} >
              <HamburgerIcon />
            </Button> }
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <DropdownMenuContainer data-test-id={"user-panel"}>
                <ControlsContainer>
                  {currentFlow !== "expired" && <ControlRow onClick={() => {
                    if(hospiceView){
                      localStorage.setItem("main_flow", 'hospice')
                      navigation?.navigate("Dashboard")
                    }else{
                      localStorage.setItem("main_flow", 'caregiver')
                      navigation?.navigate("Catalogue")
                    }

                  }}>
                    <img style={{ width: 24, height: 24 }} src={hospiceView ? honeIcon : caregiverView} />
                    <ControlsText>
                    {hospiceView ? "Hospice View" :"Caregiver View"}
                    </ControlsText>
                  </ControlRow>}
                  <ControlRow data-test-id="btn-setting" onClick={() => {navigation?.navigate("Settings2")}}>
                    <img style={{ width: 24, height: 24 }} src={settingIcon} />
                    <ControlsText>
                    Settings
                    </ControlsText>
                  </ControlRow>
                  <ControlRow onClick={() => {navigation?.navigate("Contactus")}}>
                    <img style={{ width: 24, height: 24 }} src={questionFeedback} />
                    <ControlsText>
                    Questions or Feedback
                    </ControlsText>
                  </ControlRow>
                  <ControlRow data-test-id="btn-logout" onClick={() => {
                    localStorage.setItem("main_flow", 'login')
                    handleClose()
                    setIsShowModalConfirm(true)
                  }}>
                    <img style={{ width: 24, height: 24 }} src={logoutIcon} />
                    <ControlsText>
                      Logout
                    </ControlsText>
                  </ControlRow>
                </ControlsContainer>
              </DropdownMenuContainer>
            </Popover>

          </UserPanel>
        </HeaderContainer>
      </Container>
    </>
  );
}
const Container = styled(Box)({
  borderRadius: '11.34px',
  '@media (max-width: 900px)': {
    borderRadius: 0
  },
  zIndex: 10,
  width: '100%',background: '#7C8E76',
});
const HeaderContainer = styled(Box)({
  display: 'flex',
  flex: 1,
  flexDirection: 'row',
  alignSelf: 'start',
  padding: '0px 48px 0px 48px',
  boxSizing: 'border-box',
  height: 96,
  justifyContent: 'space-between',
  alignItems: 'center',
  '@media (max-width: 900px)': {
    padding: '0px 16px 0px 16px',
    height: 80,
  },
});

const HeaderText = styled(Typography)({
  color: '#FFFFFF',
  fontWeight: 700,
  paddingLeft: 16,
  fontFamily: "Lato",
  '@media (max-width: 900px)': {
    display: 'none',
  }
});

const PartnerForCareLogoWrapper = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
})

const ImageMobile = styled(Box)({
  width: 265,
  height: 58,
  display: 'none',
  '@media (max-width: 900px)': {
    display: 'block'
  }
});

const ImageContainer = styled(Box)({
  width: 160,
  height: 68,
  display: 'block',
  '@media (max-width: 900px)': {
    display: 'none'
  }
});
const HospiceInfoContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  marginLeft: -66,
  '@media (max-width: 900px)': {
    display: 'none',
  }
});
const HospiceLogo = styled('img')({
  width: "42px",
  height: "42px",
  borderRadius: 42
});
const UserPanel = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "8px",
  position: "relative",
  padding: "20px 0px 20px 0px"
});
const HamburgerIcon = styled(MenuRoundedIcon)({
  color: "#FFFFFF",
  "&:hover": {
    cursor: "pointer",
    color: lighten('#FFFFFF', 0.3)
  }
})
const DropdownMenuContainer = styled(Box)({
  width: "300px",
  boxSizing: "border-box",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  backgroundColor: "#FFFFFF",
  borderRadius: "8px",
  overflow: 'hidden',
  boxShadow: '3px 3px 32px rgba(52, 60, 50, 0.16)',
})
const ControlsText = styled(Typography)({
  fontFamily: "Lato",
  fontWeight: 500,
  fontSize: "16px",
  color: "#586554",
})
const ControlsContainer = styled(Box)({
  display: "flex",
  flexDirection: "column"
})
const LatoText = styled(Typography)({
  fontSize: 16,
  fontFamily: "Lato",
  color: "#343C32",
})
const ControlRow = styled(Box)({
  flex: 1,
  display: "flex",
  gap: "8px",
  padding: "12px 16px 12px 16px",
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  cursor: 'pointer',
  "&:hover": {
    background: "#F2F4F1",
  }
})
const webStyles = {
  image: {
    width: 160,
    height: 68,
  },
  imageMobile: {
    width: 265,
    height: 58,
  },
  mobileModalContainer: {
    '@media (max-width: 899px)': {
      top: 'auto',
      bottom: '0',
      left: '0',
      transform: 'none',
      width: '100%',
      borderRadius: 0,
      borderTopRightRadius: 32
    }
  }
}

