Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.apiContentType = "application/json";
exports.getMethodType = "GET";
exports.fetchingVideoApiEndPoint = "/bx_block_landingpage2/landing_pages";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Videos";
exports.labelBodyText = "Videos Body";
exports.exampleVideoURL = "https://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4"
exports.btnExampleTitle = "CLICK ME";
// Customizable Area End
