import React from 'react'
import { loadStripe } from "@stripe/stripe-js";
import {
    Elements,
} from "@stripe/react-stripe-js";
import CheckoutForm from './CheckoutForm'

type Props = {
    stripePublishableKey: string
    stripeClientSecret: string
    onClose: () => void
    isShowModal: boolean
    plan: any
}
function StripePayment(props: Props) {
    const { stripeClientSecret, stripePublishableKey } = props
    const stripePromise = loadStripe(stripePublishableKey);
    const options = {
        clientSecret: stripeClientSecret,
    };

    return (
        <Elements options={options} stripe={stripePromise}>
            <CheckoutForm {...props} />
        </Elements>
    )
}

export default StripePayment