import React, { FC, ReactNode, useState } from "react";
// Customizable Area Start
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";

import { Box, Button, Grid, Link, Stack, Typography } from "@mui/material";
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { ChevronRight } from "@mui/icons-material";
import { styled } from "@mui/system";
import parse, { HTMLReactParserOptions, Element, domToReact } from 'html-react-parser';
import SwiperCore, { Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import LandingPageController, {
  ICardProps,
  ITermProps,
  navLinks,
  Props,
  quickLinks,
} from "./LandingPageController.web";
import {
  adPosterImg,
  bannerImg,
  burgerIcon,
  illustrationImg,
  locationIcon,
  logoImg,
  mailIcon,
  mailWhiteIcon,
  mobileBannerImg,
  phoneIcon,
} from "./assets";

import { CustomBreakpointWrapper } from "../../../components/src/UnauthorizedFooter";

SwiperCore.use([Autoplay]);

const options: HTMLReactParserOptions = {
  replace(domNode) {
    if (domNode instanceof Element && domNode.name === 'h5') {
      return <BaseText
        sx={{ fontSize: { xs: "20px", md: "24px" }, fontWeight: "700", color: "#343C32" }}
      >
        {domToReact(domNode.children)}
      </BaseText>
    }
    if (domNode instanceof Element && domNode.name === 'p') {
      return <SmText sx={{ fontSize: { xs: "16px", md: "18px" } }}>
        {domToReact(domNode.children)}
      </SmText>
    }
  }
};

const titleWithSubOptions: HTMLReactParserOptions = {
  replace(domNode) {
    if (domNode instanceof Element && domNode.name === 'h4') {
      return <XlText color="#343C32" sx={{ mb: { xs: '6px', md: 2.5 }, fontSize: { xs: "20px", md: "36px"} }}>
        {domToReact(domNode.children)}
      </XlText>
    }
    if (domNode instanceof Element && domNode.name === 'p') {
      return <MdText color="#586554" sx={{ fontSize: { xs: "16px", md: "20px"} }}>
        {domToReact(domNode.children)}
      </MdText>
    }
  },
};

export const UnauthPageWrapper = ({
  navigationData,
  newsletterData,
  onNavigate,
  children,
  currentItem
}: {
  navigationData?: ITermProps;
  newsletterData?: ITermProps;
  onNavigate: (screen: string) => void;
  children: ReactNode;
  currentItem?: string;
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Body sx={{ gap: { xs: 2.5, md: 10} }}>
      <CustomHeader>
        <Link underline="none" href="/" sx={{ display: { xs: 'none', md: 'block' }, paddingLeft: { xs: "50px", lg: '130px' } }}>
          <img src={logoImg} alt="Partner for Care Logo" style={{ width: '197px', height: '84px' }}/>
        </Link>
        <HeaderRight sx={{ gap: { xs: 0, md: "32px" }, flex: 1 }}>
          <ContactInfo href="mailto:support@partnerforcare.com" underline="hover" target="_blank" rel="noopener">
            <CustomIcon src={mailIcon} />
            <XsText>support@partnerforcare.com</XsText>
          </ContactInfo>
          <Box sx={{ padding: { xs: '8px 16px', md: "8px 130px" } }}>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              <Navigation>
                {navLinks.map((link, index) => {
                  if (link.value === 'EmailAccountLoginBlock') {
                    return (
                      <OutlinedButton
                        key={index}
                        variant="contained"
                        data-test-id={link.testId}
                        onClick={() => onNavigate(link.value)}
                      >{link.label}</OutlinedButton>
                    )
                  }
                  return (
                    <CustomButton
                      key={index}
                      data-test-id={link.testId}
                      sx={{ borderBottom: currentItem === `/${link.value}` ? "1px solid #7C8E76" : '1px solid transparent' }}
                      onClick={() => onNavigate(link.value)}
                    >{link.label}</CustomButton>
                  )
                })}
              </Navigation>
            </Box>
            <Box sx={{ display: { xs: 'block', md: 'none' } }}>
              <OutlinedButton
                  data-test-id="btn-menu"
                  id="burger-menu-button"
                  aria-controls={open ? 'hamburger-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  variant="contained"
                  disableElevation
                  onClick={handleClick}
                  sx={{ minWidth: 'fit-content', borderRadius:'4px', padding: 1 }}
                >
                  {<CustomIcon src={burgerIcon} sx={{ width: '19px', height: '12px' }}/>}
              </OutlinedButton>
              <StyledMenu
                id="hamburger-menu"
                MenuListProps={{
                  'aria-labelledby': 'burger-menu-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                {navLinks.map((link, index) => (
                  <MenuItem
                    key={index}
                    selected={currentItem === `/${link.value}`} 
                    onClick={() => onNavigate(link.value)}
                  >
                   <CustomIcon src={link.icon} />
                   {link.label}
                 </MenuItem>
                ))}
              </StyledMenu>
            </Box>
          </Box>
        </HeaderRight>
      </CustomHeader>
      {children}
      {navigationData && <SectionWithBg
        sx={{
          mb: { xs: "-20px", md: 0 },
          padding: { xs: "33px 0 67px", md: "38px 0" },
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundBlendMode: "overlay",
        }}
      >
        <CenterFlexCol sx={{ gap: 2.5, zIndex: 999, width: { xs: '89%', md: '65%' }, margin: 'auto' }}>
          <CenterFlexCol>
            <IllustrationImg src={illustrationImg} alt="How to access" sx={{ height: { xs: '200px', md: '280px' } }}/>
            {parse(navigationData.term, titleWithSubOptions)}
          </CenterFlexCol>
          <Box sx={{ display: "flex", gap: "20px", flexWrap: 'wrap' }}>
            {navigationData.children && navigationData.children?.length > 0 && navigationData.children.map((child, index) => (
              <DirectionCard key={index} sx={{ flexBasis: { xs: "100%", lg: 0 }, gap: { xs: 2.5, md: "18px"}, }}>
                {parse(child.definition, {
                  replace(domNode) {
                    if (domNode instanceof Element && domNode.name === 'h4') {
                      return <XlText color="#343C32" sx={{ mb: { xs: "2px", md: 0 }, fontSize: { xs: "20px", md: "30px"} }}>
                        {domToReact(domNode.children)}
                      </XlText>
                    }
                    if (domNode instanceof Element && domNode.name === 'p') {
                      return <LgText color="#586554" sx={{ minHeight: { xs: "fit-content", md: "96px"}, fontSize: { xs: "16px", md: "22px"}  }}>
                        {domToReact(domNode.children)}
                      </LgText>
                    }
                  }
                })}
                <OutlinedButton
                  data-test-id="btn-auth"
                  variant="contained"
                  sx={{ width: "248px", padding: "10px 16px" }}
                  onClick={() => onNavigate(index % 2 === 0 ? "EmailAccountLoginBlock" : "EmailAccountRegistration")}
                >
                  {index % 2 === 0 ? 'login' : 'register'}
                </OutlinedButton>
              </DirectionCard>
            ))}
          </Box>
        </CenterFlexCol>
      </SectionWithBg>}
      {newsletterData && <Section
        sx={{
          mt: { xs: '0', md: "-80px" },
          mb: { xs: "-20px", md: "-80px" },
          padding: { xs: "40px 16px", md: "80px 0" },
          gap: "12px",
          textAlign: "center",
        }}
      >
        <CenterFlexCol sx={{ width: { xs: '100%', md: '50%' }, marginX: 'auto' }}>
          <XlText color="#343C32" sx={{ fontSize: { xs: "20px", md: "36px" } }}>
            {parse(newsletterData.term)}
          </XlText>
          <MdText color="#343C32" sx={{ fontSize: { xs: "16px", md: "20px" } }}>
            {parse(newsletterData.definition)}
          </MdText>
          <OutlinedButton
            variant="contained"
            sx={{
              marginTop: 2.5,
              padding: "10px 16px",
              display: "flex",
              gap: 1
            }}
            onClick={() => onNavigate("Contactus")}
          >
            <CustomIcon src={phoneIcon} />
            <XsText>contact us</XsText>
          </OutlinedButton>
        </CenterFlexCol>
      </Section>}
      <CustomBreakpointWrapper>
        <CustomFooter data-test-id="footer-section" sx={{ p: { xs: '40px 16px', md: "60px" } }}>
          <Grid container sx={{ mb: { xs: 2.5, md: "85px" } }}>
            <Grid item xs={12} md={4.5} sx={{ borderBottom: { xs: "1px solid #7C8E7680", md: "none" }, padding: { xs: '20px 0', md: 0 } }}>
              <Box
                sx={{
                  padding: { xs: "0", md: "20px 40px" },
                  borderRight: { xs: "none", md: "1px solid #7C8E7680" },
                  height: "100%",
                  display: { xs: 'flex', md: 'block' },
                  flexDirection: 'column',
                  alignItems: 'center'
                }}
              >
              <img src={logoImg} alt="Partner for Care Logo" style={{ width: '197px', height: '84px' }}/>
              <SmText sx={{ mt: 2.5, textAlign: { xs: 'center', md: 'left' } }}>
                  <b>Partner for Care</b> is your trusted companion, offering a
                  comprehensive repository of resources and guidance to support you
                  every step of the way in your caregiving journey. With our
                  user-friendly platform, you can access vital information and
                  assistance whenever you need it.
                </SmText>
              </Box>
            </Grid>
            <Grid item xs={12} md={3.5} sx={{ borderBottom: { xs: "1px solid #7C8E7680", md: "none" }, padding: { xs: '20px 0', md: 0 } }}>
              <Box
                sx={{
                  padding: { xs: "0", md: "20px 40px" },
                  borderRight: { xs: "none", md: "1px solid #7C8E7680" },
                  height: "100%",
                }}
              >
                <BaseText fontSize={24} fontWeight="500">
                  Quick Links
                </BaseText>
                <Links>
                  {quickLinks.map((link, index) => (
                    <Box
                      key={index}
                      sx={{ display: "flex", gap: "8px", alignItems: "center" }}
                    >
                      <ChevronRight />
                      <CustomButton
                        sx={{ borderBottom: "1px solid transparent", minWidth: 0 }}
                        onClick={() => onNavigate(link.value)}
                        data-test-id={!!link?.testId && link?.testId}
                      >
                        {link.label}
                      </CustomButton>
                    </Box>
                  ))}
                </Links>
              </Box>
            </Grid>
            <Grid item xs={12} md={4} sx={{ padding: { xs: '20px 0', md: 0 } }}>
              <Box sx={{ padding: { xs: "0", md: "20px 40px" }, height: "100%" }}>
                <Links sx={{ mt: 0 }}>
                  <Box sx={{ display: "flex", gap: "16px" }}>
                    <CustomIcon src={locationIcon} />
                    <SmText fontWeight="500">
                      <b>Partner for Care</b>
                      <br />
                      15585 Monterey Street
                      <br />
                      Suite B<br />
                      Morgan Hill, CA 95037
                    </SmText>
                  </Box>
                  <Box sx={{ display: "flex", gap: "16px" }}>
                    <CustomIcon src={mailWhiteIcon} />
                    <MailLink href="mailto:support@partnerforcare.com" underline="hover" target="_blank" rel="noopener">
                      support@partnerforcare.com
                    </MailLink>
                  </Box>
                </Links>
              </Box>
            </Grid>
          </Grid>
          <CopyrightLayout sx={{ flexDirection: { xs: 'column', sm: 'row' } }}>
            <SmText fontWeight="600">
              © Copyright 2024 | <b>Partner for Care</b> | All Rights Reserved
            </SmText>
            {currentItem === '/LandingPage' &&
              (
                <SmText fontWeight="600">
                  Header Image Designed by <Link href="https://www.freepik.com" target="_blank" rel="noopener noreferrer" sx={{ color: '#FFF', textDecorationColor: '#FFF' }}>Freepik</Link>
                </SmText>
              )}
          </CopyrightLayout>
        </CustomFooter>
      </CustomBreakpointWrapper>
    </Body>
  )}
// Customizable Area End

export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }
  // Customizable Area Start
  renderServiceCard: FC<ICardProps> = ({ thumbnail, children }) => (
    <Card sx={{ mx: { xs: 'auto' }, maxWidth: { xs: '328px', md: '384px' } }}>
      <CardImage
        src={thumbnail}
        sx={{ width: { xs: "160px", md: "180px" }, height: { xs: "160px", md: "180px" }  }}
      />
      <CenterFlexCol sx={{ gap: "13px", px: { xs: 2, md: 2.5 } }}>{children}</CenterFlexCol>
    </Card>
  );
  // Customizable Area End
  render() {
    // Customizable Area Start
    return (
      <UnauthPageWrapper
        currentItem={this.props.navigation.getLocation() === '/' ? '/LandingPage' : this.props.navigation.getLocation()} 
        onNavigate={(screen) => this.naviagteTo(screen)} 
        navigationData={this.state.navigationData}
        newsletterData={this.state.newsletterData}
      >
        <Box sx={{ position: "relative" }}>
            <BannerImg
              src={bannerImg}
              alt="Partner for Care Banner"
              sx={{ minHeight: '400px', maxHeight: '596px', minWidth: '375px' }}
            />
            <Overview sx={{ 
              bottom: { xs: "-25%", md: "-10%" }, 
              right: { xs: "16px", md: "130px" }, 
              left: { xs: "16px", md: 'calc(100% - 630px)' },  
              gap: { xs: '6px', md: 2.5 } 
            }}>
              <XlText sx={{ textTransform: "capitalize", fontSize: { xs: '20px', md: '32px' } }}>
                A Trusted Resource Dedicated to Caregivers
              </XlText>
              <SmText sx={{ fontSize: { xs: '16px', md: '18px' } }}>
                <b>Partner for Care </b>
                ensures that caregivers never feel alone as they tend to their
                loved ones in Hospice care. We offer a comprehensive resource hub
                with information and guidance for every step of the journey.
              </SmText>
            </Overview>
        </Box>
        {this.state.terms.length > 0 && <>
          {this.state.terms[0] && <Section sx={{ mt: { xs: 12.5, md: 0 }, paddingX: { xs: 2, md: "130px"} }}>
            <SectionHeading sx={{ 
              fontSize: { xs: "20px", md: "24px" }, 
              lineHeight: { xs: "24px", md: "32px" }, 
              '& span': { fontSize: { xs: "20px", md: "36px"} } 
            }}>
              {parse(this.state.terms[0]?.term)}
            </SectionHeading>
            <Box sx={{ 
              display: { xs: 'flex', md: 'block' }, 
              flexDirection: 'column', 
              alignItems: 'center', 
            }}>
              <Box sx={{ 
                float: { xs: "none", md: "left" },
                mr: { xs: 0, md: "20px" }, 
                mb: { xs: 2.5, md: 0 }, 
                maxWidth: { xs: '430px' },
                height: '256px',
                width: 'auto' 
              }}>
                <Video
                  controls
                  poster={adPosterImg}
                  src={this.state.videoUrl}
                >
                  Your browser doesn’t support HTML5 video.
                </Video>
              </Box>
              <Box sx={{ color: "#586554" }}>
                <MdText sx={{ fontSize: { xs: "16px", md: "20px" }, "& p": { marginBottom: "8px" } }}>
                  {parse(this.state.terms[0]?.definition)}
                </MdText>
              </Box>
            </Box>
          </Section>}
          {this.state.terms[1] && <Section>
            <SectionHeading sx={{ 
              fontSize: { xs: "20px", md: "24px" }, 
              lineHeight: { xs: "24px", md: "32px" }, 
              '& span': { fontSize: { xs: "20px", md: "36px"} } 
            }}>
              {parse(this.state.terms[1]?.term)}
            </SectionHeading>
            <SwiperContainer>
              <Swiper
                slidesPerView={1}
                breakpoints={{
                  512: {
                    slidesPerView: 1.5,
                    spaceBetween: 3
                  },
                  600: {
                    slidesPerView: 2,
                    spaceBetween: 6,
                  },
                  768: {
                    slidesPerView: 2.5,
                    spaceBetween: 6,
                  },
                  1024: {
                    slidesPerView: 3,
                    spaceBetween: 12,
                  },
                  1280: {
                    slidesPerView: 3.5,
                    spaceBetween: 12,
                  },
                  1536: {
                    slidesPerView: 4.5,
                    spaceBetween: 24,
                  }
                }}
                centeredSlides
                loop
                autoplay={{
                  delay: 2000,
                  disableOnInteraction: false,
                }}
                speed={1000}
              >
                {this.state.terms[1] && this.state.terms[1]?.children && this.state.terms[1]?.children.map((child, index) => {
                  const thumbnail = child.images[0];
                  const content = parse(child.definition, options);
                  return (
                    <SwiperSlide key={index}>
                      {this.renderServiceCard({ thumbnail: thumbnail.url, children: content })}
                    </SwiperSlide>
                )})}
              </Swiper>
            </SwiperContainer>
          </Section>}
          {this.state.terms[2] && <Section sx={{ mb: { xs: 4, md: 0 } }}>
            <SectionHeading sx={{ 
              fontSize: { xs: "20px", md: "24px" }, 
              lineHeight: { xs: "24px", md: "32px" }, 
              '& span': { fontSize: { xs: "20px", md: "36px"} } 
            }}>
              {parse(this.state.terms[2]?.term)}
            </SectionHeading>
            <BenefitContainer sx={{ width: { xs: "fit-content", md: "100%" }, p: { xs: "16px", md: 0 } }}>
              {this.state.terms[2].children && this.state.terms[2].children.map((child, index) => {
                const parsingOptions: HTMLReactParserOptions = {
                  replace(domNode) {
                    if (!(domNode instanceof Element)) return;
                    const replacementByName: { [key: string]: JSX.Element } = {
                      "h4": <BenefitTitle sx={{ fontSize: { xs: "20px", md: "30px" } }}>{domToReact(domNode.children)}</BenefitTitle>,
                      "li": (
                        <li>
                          <LgText sx={{ color: "#586554", fontSize: { xs: "16px", md: "22px" } }}>
                            {domToReact(domNode.children)}
                          </LgText>
                        </li>
                      ),
                    };
                    if (!replacementByName[domNode.name]) return;
                    return replacementByName[domNode.name];
                  },
                };
                return (
                  <BenefitCard 
                    key={index} 
                    sx={{ 
                      flex: 1, 
                      flexBasis: { xs: '100%', md: 0 }, 
                      ml: { xs: 0, md: index === 0 ? '130px' : 0 },
                      mr: { xs: 0, md: index === 1 ? '130px' : 0 } 
                    }}
                  >
                    {parse(child.definition, parsingOptions)}
                  </BenefitCard>
              )})}
            </BenefitContainer>
          </Section>}
        </>}
        
      </UnauthPageWrapper>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const Body = styled(Box)({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  flex: 1,
  background: "#FFF",
  overflowX: "hidden",
});
const CustomHeader = styled("header")({
  width: "100%",
  background: "rgba(26, 34, 24, 0.8)",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  color: "white",
  position: "absolute",
  top: 0,
  zIndex: 9999,
});
const HeaderRight = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
});
const ContactInfo = styled(Link)({
  borderRadius: "0 0 0 50px",
  backgroundColor: "#F2F4F1",
  color: "#586554",
  padding: "6px 60px",
  display: "flex",
  gap: "8px",
});
const Navigation = styled(Box)({
  display: "flex",
  gap: "16px",
});
const CustomButton = styled(Button)({
  minWidth: '79px',
  fontFamily: "Lato",
  fontSize: "16px",
  fontWeight: "500",
  color: "white",
  textTransform: "capitalize",
  padding: "4px 0",
  borderRadius: '8px',
  ":hover": {
    backgroundColor: "transparent",
    borderBottom: "1px solid #7C8E76",
  },
});
const OutlinedButton = styled(CustomButton)({
  backgroundColor: "#71816B",
  padding: "4px 30px",
  border: 'none',
  ":hover": {
    backgroundColor: "#586554",
    border: 'none',
  },
});
const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={1}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))({
  '& .MuiPaper-root': {
    marginTop: '9px',
    minWidth: 300,
    border: '1px solid #FFF',
    borderRadius: 8,
    color: 'rgb(55, 65, 81)',
    boxShadow: 'rgba(0, 0, 0, 0.06) 0px 8px 32px 0px, rgba(0, 0, 0, 0.03) 0px 4px 8px 0px',
    '& .MuiMenu-list': {
      padding: 0,
      '& li': {
        '&:hover': {
          backgroundColor: '#F2F4F1 !important',
        },
      },
    },
    '& .MuiMenuItem-root': {
      padding: '12px 16px',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      fontFamily: 'Lato',
      fontSize: '16px',
      fontWeight: '500',
      color: '#586554',
      textTransform: 'capitalize',
      '& img': {
        width: '24px',
        heigth: '24px',
      },
      '&.Mui-selected': {
        backgroundColor: '#F2F4F1',
      },
      '&:focus, &:hover': {
        backgroundColor: '#F2F4F1',
      },
    }, 
  },
});
const BannerImg = styled('img')({
  width: "100%", 
  height: "100%", 
  objectPosition: "center", 
  objectFit: 'cover',
})
const Overview = styled(Box)({
  borderRadius: "8px",
  position: "absolute",
  zIndex: 999,
  display: "flex",
  flexDirection: "column",
  gap: "20px",
  background: "rgba(26, 34, 24, 0.9)",
  padding: "20px",
  "& p": { color: "white" },
});
const BaseText = styled(Typography)({
  fontFamily: "Lato",
  "& p": { margin: 0 },
});
const XsText = styled(BaseText)({
  fontSize: "16px",
  fontWeight: "500",
});
const SmText = styled(BaseText)({
  fontSize: "18px",
  fontWeight: "400",
});
const MdText = styled(BaseText)({
  fontSize: "20px",
  fontWeight: "400",
});
const LgText = styled(BaseText)({
  fontSize: "22px",
  fontWeight: "400",
});
const XlText = styled(BaseText)({
  fontSize: "32px",
  fontWeight: "700",
});
const CustomIcon = styled("img")({
  width: "24px",
  height: "24px",
  objectFit: "contain",
});
const Section = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "20px",
});
const SectionHeading = styled(BaseText)({
  fontWeight: "700",
  color: "#343C32",
  textAlign: "center",
  "& span": {
    color: "#94CE81",
  },
});
const CenterFlexCol = styled(Box)({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});
const Video = styled('video')({
  width: "100%",
  height: "256px",
  borderRadius: "8px",
  background: "rgba(0, 0, 0, 0.5)",
  objectFit: 'contain'
})
const Card = styled(CenterFlexCol)({
  height: "94%",
  maxWidth: "384px",
  border: "1px solid rgba(148, 206, 129, 0.4)",
  borderRadius: "8px",
  padding: "12px 20px",
  gap: "20px",
  color: "#586554",
  textAlign: "center",
});
const CardImage = styled('img')({
  objectFit: "contain"
})
const SwiperContainer = styled('div')({
  ".swiper-slide": { height: "auto" },
  width: '100%',
});
const BenefitContainer = styled(Box)({
  width: '100%',
  display: "flex",
  gap: "20px",
  justifyContent: 'center',
  flexWrap: 'wrap',
});
const BenefitCard = styled(CenterFlexCol)({
  borderRadius: "8px",
  padding: "40px 20px",
  backgroundColor: "#FFF0E8",
  gap: "20px",
  flex: 1 
});
const BenefitTitle = styled(BaseText)({
  fontSize: "30px",
  fontWeight: "700",
  color: "#343C32",
});
const SectionWithBg = styled(Box)({
  backgroundColor: '#FAFDFE',
  textAlign: "center",
});
const IllustrationImg = styled('img')({
  width: 'auto', 
  objectFit: 'contain',
});
const DirectionCard = styled(CenterFlexCol)({
  flex: 1,
  border: "2px dashed #94CE81",
  borderRadius: "8px",
  padding: "40px 20px",
});
const CustomFooter = styled("footer")({
  background: "#343C32",
  color: "white",
});
const Links = styled(Box)({
  marginTop: "20px",
  display: "flex",
  flexDirection: "column",
  gap: "16px",
});
const MailLink = styled(Link)({
  fontFamily: 'Lato',
  fontSize: "18px",
  fontWeight: "500",
  color: "white",
});
const CopyrightLayout = styled(Stack)({
  borderTop: "1px solid #7C8E7680", 
  paddingTop: '16px', 
  justifyContent: 'space-between',
  gap: '8px'
})
// Customizable Area End
