import React from 'react'
import { loadStripe } from "@stripe/stripe-js";
import {
    Elements,
} from "@stripe/react-stripe-js";
import StripeCheckout from './StripeCheckout'


function StripeListener(props: any) {
    const clientSecret = new URLSearchParams(window.location.search).get(
        "payment_intent_client_secret"
      );
    const stripePk  = localStorage.getItem('stripe_pk') 
    const stripePromise = loadStripe(stripePk || 'pk_test_51PBezHRv0fG0yQZXBaZMD4DUeqX58JvXQnPL7wZTHfLaCbxpiIRPuJhyAI8CBbhQADoHdXqOYIZreN5ehQjpZqpM007zyiFCjj');
    return (
        clientSecret ? <Elements options={{clientSecret}} stripe={stripePromise}>
            <StripeCheckout {...props} stripeClientSecret={clientSecret} />
        </Elements> : <></>
    )
}

export default StripeListener